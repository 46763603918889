@import 'styles/mixins.scss';

.detailsWrapper {
    padding: 2rem 0;
    .dataWrapper {
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        @include special-sections {
            justify-content: center;
            flex-direction: column;
            gap: 1rem;
        }

        .dataLeft {
            width: 32.5%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include special-sections {
                width: 100%;
            }

            .dataRow {
                display: flex;

                .dataCell {
                    flex: 1;
                }

                .dataCellBig {
                    flex: 1;

                    @include special-sections {
                        flex: 2;
                    }

                    @include small-phone-only {
                        flex: 1;
                    }
                }

                .cellDisplay {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
            }
        }

        .dataRight {
            width: 57.5%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include special-sections {
                width: 100%;
            }

            .dataRow {
                display: flex;

                .dataCell {
                    flex: 1;
                }

                .dataCellBig {
                    flex: 2;
                    display: flex;
                    justify-content: space-between;

                    @include small-phone-only {
                        flex-direction: column;
                        gap: 1rem;
                    }

                    @include small-phone-only {
                        flex: 1;
                    }
                }
            }
        }
    }

    .dateWrapper {
        padding: 0 2rem;
        margin: 1rem 0;
        display: flex;
        align-items: center;

        .dateFirst {
            flex: 1;

            @include tablet-portrait {
                flex: 1;
            }
        }

        .dateSecond {
            flex: 2;

            @include tablet-portrait {
                flex: 1;
            }
        }
    }

    .visualizeOrderWrapper {
        padding: 0 2rem;
        margin: 1rem 0 2rem 0;

        @include phone-only {
            display: flex;
            justify-content: center;
        }
    }

    .documentsWrapper {
        padding: 0 2rem;
        margin: 1rem 0 2rem 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .documentsContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .documentWrapper {
                border: 1px solid #ced1dc;
                padding: 1rem;
                border-radius: 10px;
                width: 100%;
                display: flex;
                align-items: center;

                .cellWrapper {
                    flex: 1.25;
                    display: flex;
                    justify-content: space-between;

                    @include special-sections {
                        flex: 2;
                    }
                    @include tablet-portrait {
                        flex: 2;
                    }
                }

                .bigCellWrapper {
                    flex: 1.75;
                    display: flex;
                    justify-content: flex-end;

                    @include special-sections {
                        flex: 1;
                    }

                    .downloadWrapper {
                        all: none;
                    }
                }
            }
        }

        .addWrapper {
            display: flex;
            justify-content: flex-end;

            @include phone-only {
                display: flex;
                justify-content: center;
            }
        }
    }

    .commentsWrapper {
        padding: 0 2rem;
    }
}
