@import 'styles/mixins.scss';

.container_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    @include phone-only {
        display: flex;
        flex-direction: column;
    }

    @include small-phone-only {
        display: flex;
        flex-direction: column;
    }
}

.search_container {
    width: 100%;
    position: relative;

    @include small-phone-only {
        min-width: 200px;
    }
}

.container_2 {
    display: flex;
    flex-direction: column;
    gap: 32px;

    ul {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            gap: 64px;

            div {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
}

.container_3 {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;

            /* Headers */
            &:first-child {
                span {
                    font-weight: bold;
                }
            }

            > * {
                flex: 1;
            }

            > section {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 12px;

                svg {
                    width: 16px;
                    height: 16px;
                    opacity: 0.3;
                    cursor: pointer;
                    transition: opacity 0.2s ease;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

.container_4 {
    /* Document list */
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border: 1px solid #ced1dc;
            border-radius: 10px;

            > * {
                flex: 1;
            }
        }
    }
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.add_unit_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
