@import 'styles/mixins.scss';

.pageContainer {
    padding: 0 55px 55px 55px;

    @include phone-only {
        padding: 0 20px 20px 20px;
    }

    .componentWrapper {
        max-width: 1090px;
        margin: 0 auto;

        .headerContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block: 32px;

            @include desktop {
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                gap: 1rem;
            }

            @include tablet-portrait {
                flex-direction: row;
                justify-content: space-between;
            }

            @include small-phone-only {
                flex-direction: column;
                align-items: center;
            }

            .titleContainer {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-shrink: 0;
                gap: 8px;
            }

            .actionAreaContainer {
                display: flex;
                align-items: center;
                gap: 16px;
                padding-left: 32px;

                @include desktop {
                    padding-left: 0;
                }

                @include tablet-portrait {
                    flex-direction: column;
                    align-items: initial;
                }
            }
        }
    }
}
