@import 'styles/mixins.scss';

.offersWrapper {
    padding: 3.5rem 2rem 2rem 2rem;
    max-width: 1090px;
    display: block;
    margin: 0 auto;

    .headerWrapper {
        padding: 0 0 1rem 0;

        .headerContainer {
            display: flex;
            justify-content: space-between;

            .infoWrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }

        .userHeaderContainer {
            display: flex;
            justify-content: flex-start;

            .infoWrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    .tabsWrapper {
        padding: 1rem 0 1rem 0;
    }
}
