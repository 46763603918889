//Fonts imports
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

//Mixins import
@import 'styles/mixins.scss';

//Component imports
@import 'lib/components/Buttons/buttons.scss';
@import 'lib/components/Appbar/appbar.scss';

//Pages imports
@import 'pages/Users/users.scss';
@import 'nomenclators/Vehicles/Vehicles.scss';

@import 'pages/ClientSource/client-source.scss';
@import 'pages/Clients/clients.scss';
@import 'pages/Profile/profile.scss';
@import 'pages/Objectives/Objectives.scss';
//Modals imports

body {
    margin: 0;
    background-color: #f4f5f7;
    font-family: 'Montserrat';
}

* {
    box-sizing: border-box;
}

.MuiTableCell-root {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.95) !important;
    border: none !important;
    white-space: nowrap;
}

// Styles for the arrow button from table row
.details-arrow-style {
    cursor: pointer;

    &:hover {
        svg {
            path {
                fill: #5b80ba;
            }
        }
    }
}
