.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;

    hr {
        width: 100%;
    }
}
