.button-content-wrapper {
    width: 210px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    svg {
        font-size: 40px;
        width: 40px;
        height: 40px;
        path {
            transition: 0.2s ease;
        }
    }

    h1 {
        font-size: 20px;
        font-weight: 600;
    }
}

.small-button-content-wrapper {
    width: 212.5px;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;

    svg {
        font-size: 40px;
        width: 40px;
        height: 40px;
        path {
            transition: 0.2s ease;
        }
    }

    h1 {
        font-size: 20px;
        font-weight: 600;
    }
}
