.actions-class {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: #5b80ba;
    }
}

.client-mid-wrapper {
    padding: 0.5rem 0 1rem 0;
    flex-direction: row;
    background-color: #ffffff;
    border-radius: 0 0 1rem 1rem;

    @include special-sections {
        flex-direction: column;
        align-items: center;
    }

    .client-details-actions {
        padding: 0 5%;
        display: flex;
        justify-content: space-between;

        div {
            display: flex;
            gap: 10px;
        }
    }

    .client-details-actions {
        display: flex;
        align-items: center;

        @include special-sections {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
        }

        .buttons-action-contact {
            margin-left: auto;
            margin-right: 20px;
            text-align: center;

            @include special-sections {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .buttons-action-edit {
            @include special-sections {
                margin-right: 0 !important;
            }
        }
    }

    .client-details-info {
        display: flex;
        padding: 4% 5% 4% 7%;

        @include special-sections {
            flex-direction: column;
            align-items: center;
            gap: 50px;
        }

        .left-side {
            display: grid;
            grid-template-columns: 80px 250px;
            @include special-sections {
                grid-template-columns: 20% 80%;
                width: 70%;
                margin: 0 auto;
                text-align: center;
            }

            @include tablet-portrait {
                width: 100%;
            }

            .first-column,
            .second-column {
                display: grid;
                gap: 8px;

                @include special-sections {
                    gap: 20px;
                }
            }
        }

        .right-side {
            margin-left: auto;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @include special-sections {
                grid-template-columns: 20% 70% 10%;
                width: 70%;
                margin: 0 auto;
                text-align: center;
            }

            @include tablet-portrait {
                width: 100%;
            }

            .first-column,
            .second-column,
            .third-column {
                display: grid;
                gap: 8px;
            }

            .first-column {
                @include small-phone-only {
                    width: 60px;
                }
            }

            .second-column {
                @include small-phone-only {
                    width: 140px;
                }
            }

            .third-column {
                align-content: space-between;
                align-items: flex-end;
                justify-content: end;
            }
        }
    }

    .client-list-members {
        padding-left: 8%;

        .list-of-names {
            padding: 2% 0;
            width: 92%;

            .listItem {
                border-bottom: 1px solid lavender;

                &:last-child {
                    border-bottom: none;
                }
            }

            .member-details {
                display: grid;

                .member-details-address {
                    display: grid;

                    .member-address-info {
                        display: flex;
                        padding: 10px 0;

                        @include tablet-portrait {
                            display: grid;
                            width: 100%;
                        }
                    }
                }

                .member-details-brand,
                & .member-more-details {
                    padding-top: 30px;
                }

                .member-details-brand {
                    column-gap: 10%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .member-details-brand-column {
                        display: grid;
                    }
                }

                .member-more-details {
                    display: grid;
                }
            }
        }
    }

    .gdpr-agreement {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2% 8% 2% 7%;

        div {
            display: flex;
            gap: 10px;
        }

        @include desktop {
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .client-documents-section {
        padding: 2% 5% 4% 7%;

        .documentTitle {
            margin-bottom: 1rem;
        }

        .client-documents {
            display: grid;

            .documentsWrapper {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .file-box-document {
                display: flex;
                align-items: center;
                text-align: left;
                margin-top: 8px;
                border: 1px solid #ced1dc;
                border-radius: 10px;
                width: 98%;
                height: 52px;

                .delete-document,
                .download-document {
                    color: rgba(0, 0, 0, 0.3);
                }

                .delete-document,
                .download-document {
                    &:hover {
                        color: #5b80ba !important;
                        cursor: pointer;
                    }
                }
            }

            .client-add-document {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                padding: 20px 20px 0 0;
            }
        }
    }
}

.add-document-wrapper,
.add-adviser-client-wrapper,
.add-clients-wrapper,
.add-date-manufactoring-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 568px;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    .document-wrapper,
    .adviser-client-wrapper {
        position: relative;
        display: table;

        .checkboxGroupError {
            color: #ff4b55;
            font-family: Montserrat, sans-serif;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.66;
        }
    }
}

.add-clients-wrapper,
.add-date-manufactoring-wrapper {
    width: 485px;
}

.add-date-manufactoring-wrapper {
    padding-top: 0.5rem;
    padding-bottom: 0;
    gap: 0;

    .dates-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.5rem;

        .date-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            .column-one {
                display: flex;
                width: 40%;
                font-weight: 400px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.62);
            }
        }
    }

    .content-date-picker {
        display: flex;
        flex-direction: column;
        padding: 10px 5px 0 5px;

        .dates-picker-area {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
            padding: 20px 0 0 0;
            align-items: center;
        }
    }

    .template-date-picker {
        width: 40%;
    }
}
