.container {
    padding-inline: 24px;
}

.column_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 22px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 50%;
}
