.client-source-wrapper {
    padding: 3.5rem 2rem 2rem 2rem;
    max-width: 1090px;
    display: block;
    margin: 0 auto;

    .header-wrapper {
        padding: 0 0 1rem 0;

        .header-container {
            display: flex;
            justify-content: space-between;

            @include phone-only {
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 1rem;
            }

            .info-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .buttons-wrapper {
                display: flex;
                flex-direction: row;
                gap: 16px;
            }
        }

        .user-header-container {
            display: flex;
            justify-content: flex-start;

            .info-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    .table-wrapper {
        padding: 1rem 0 1rem 0;

        .actions-class {
            display: flex;
            justify-content: flex-end;
        }
    }
}
