@import 'styles/mixins.scss';

.modalRowOfferDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalDocumentsWrapper {
    padding: 0 1rem;
    margin: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .modalDocumentsContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .modalDocumentWrapper {
            border: 1px solid #ced1dc;
            padding: 1rem;
            border-radius: 10px;
            width: 100%;
            display: flex;
            align-items: center;

            .modalCellWrapper {
                flex: 1.25;
                display: flex;
                justify-content: space-between;
                padding-right: 5%;

                @include special-sections {
                    flex: 2;
                }
                @include tablet-portrait {
                    flex: 2;
                }
            }

            .modalMiddleCellWrapper {
                flex: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .modalBigCellWrapper {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                @include special-sections {
                    flex: 0.75;
                }

                .modalDownloadWrapper {
                    all: none;
                }
            }
        }
    }

    .modalAddDocumentWrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 15px;
        border: 2px solid rgba(211, 211, 211, 0.446);
        border-radius: 20px;
        box-shadow: 2px 2px 10px -2px rgba(211, 211, 211, 0.5);

        .modalDocumentWrapper {
            position: relative;
            display: table;

            // .checkboxGroupError {
            //     color: #ff4b55;
            //     font-family: Montserrat, sans-serif;
            //     font-weight: 400;
            //     font-size: 0.75rem;
            //     line-height: 1.66;
            // }
        }
    }

    .modalAddWrapper {
        display: flex;
        justify-content: flex-end;

        .buttons {
            display: flex;
            gap: 1rem;
        }

        @include phone-only {
            display: flex;
            justify-content: center;
        }
    }
}
