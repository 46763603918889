.hoverGroupV {
    display: flex;
    align-items: center;

    &:hover {
        .buttonForDelete {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .buttonForDelete {
        opacity: 0;
        transform: translateX(-0.5rem);
        transition-duration: 0.5s;
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
    }
}
