.radioGroupWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    label {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        margin-right: 32px;
        margin-bottom: 12px;
        cursor: pointer;

        .labelName {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.62);
            user-select: none;
        }
    }

    input {
        flex-shrink: 0;
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;
        padding: 0;

        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: red;
        width: 18px;
        height: 18px;
        border: 2px solid #e9e9e9;
        border-radius: 20px;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }

    input::before {
        flex-shrink: 0;
        content: '';
        width: 10px;
        height: 10px;
        transform: scale(0);
        border-radius: 20px;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 10px 10px #5b80ba;
    }

    input:checked::before {
        transform: scale(1);
    }
}
