.goBottom {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 999px;
  background: #5b80ba;
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: background 0.2s ease, opacity 0.2s ease;
  z-index: 9999;
  user-select: none;

  &:hover {
    background-color: #5b80ba;

    svg {
      animation: arrowHop 0.5s ease forwards;
    }
  }
}

.visible {
  opacity: 1;
  pointer-events: all;
}

@keyframes arrowHop {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(7.5px);
  }
  100% {
    transform: translateY(0);
  }
}
