@import 'styles/mixins.scss';

.taskWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    border-left: 2px solid #8e99a7;
    padding-bottom: 1rem;

    &:last-child {
        padding-bottom: 5rem;
    }

    .dotRed {
        background-color: #ff4b55;
    }

    .dotGreen {
        background-color: #009c10;
    }

    .dotBlue {
        background-color: #8e99a7;
    }

    .bigDot {
        height: 16px;
        width: 16px;
        border-radius: 10px;
        display: inline-block;
        margin-left: -9px;
    }

    .taskContainer {
        padding: 1rem 2rem;
        width: 100%;
        border-radius: 0px 10px 10px 10px;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 1px 2px rgba(36, 36, 36, 0.25);

        @include desktop {
            flex-direction: column;
        }

        .data {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .svgRed {
                svg {
                    path {
                        fill: #ff4b55;
                    }
                }
            }

            .svgBlue {
                svg {
                    path {
                        fill: #5b80ba;
                    }
                }
            }

            .svgGreen {
                svg {
                    path {
                        fill: #009c10;
                    }
                }
            }

            .dataTop {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
            }

            .dataCenter {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .dataBottom {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.75rem;

                @include special-sections {
                    flex-direction: column;
                    gap: 0.25rem;
                }

                .dot {
                    min-height: 6px;
                    min-width: 6px;
                    background-color: #8e99a7;
                    border-radius: 10px;
                    display: inline-block;
                }
            }
        }

        .actionButtons {
            display: flex;
            gap: 0.25rem;
            align-items: center;

            @include desktop {
                margin-top: 1rem;
                justify-content: center;
            }
        }
    }
}
