.container {
    position: relative;
}

.list {
    position: absolute;
    width: 100%;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #f4f4f4;
    max-height: 400px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    overflow-y: scroll;
    transition: 0.3s ease;
    pointer-events: none;
    transform: translateY(10px) scale(0.9);
    z-index: 999;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            padding: 16px;
            border-bottom: 1px solid #f4f4f4;
            color: rgba(0, 0, 0, 0.4);
            transition: color 0.2s ease;
            font-weight: 600;
            cursor: pointer;

            .listItemWrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .circleStatus_red {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ff4b55;
                }

                .circleStatus_green {
                    margin-left: 2px;
                    width: 13px;
                    height: 13px;
                    border-radius: 15px;
                    background-color: #009c10;
                }
            }

            .listItem {
                display: flex;
                align-items: flex-end;
                gap: 15px;

                .companyLabels {
                    font-size: 0.75rem;
                }

                .clientLegit {
                    color: rgba(0, 0, 0, 0.6);
                }

                .sleepingClient {
                    font-size: 0.75rem;
                    padding: 3px 10px;
                    background-color: #ff4b5575;
                    color: white;
                    border-radius: 15px;
                }

                .listItem_consilier {
                    display: flex;
                    gap: 3.5px;
                    font-size: 0.75rem;

                    .consilierSpan_legit {
                        transition: color 0.2s ease;
                        color: #5b80ba75;

                        span {
                            transition: color 0.2s ease;
                            color: #009c1075;
                        }
                    }
                }

                .listItem_brand {
                    font-size: 0.75rem;

                    .brandSpan_legit {
                        color: #5b80ba75;
                        transition: color 0.2s ease;
                    }
                }
            }

            &:hover {
                .clientLegit {
                    color: rgba(0, 0, 0, 0.8);
                }
                .listItem_consilier .consilierSpan_legit {
                    color: #5b80ba;
                }

                .listItem_consilier .consilierSpan_legit span {
                    color: #009c10;
                }

                .listItem_brand .brandSpan_legit {
                    color: #5b80ba;
                }

                .sleepingClient {
                    background-color: #ff4b55;
                }
            }

            &:last-child {
                border: none;
            }
        }
    }
}

.opened {
    opacity: 1;
    transform: translateY(0) scale(1);
    pointer-events: all;
}
