@import 'styles/mixins.scss';

// .tasksWrapper {
//     padding: 3.5rem 2rem 2rem 2rem;
//     max-width: 1090px;
//     display: block;
//     margin: 0 auto;

.timeLine {
    width: 2px;
    background: #8e99a7;
    height: 100%;
    margin-left: 6px;
}

.headerWrapper {
    padding: 0 0 1rem 0;

    .headerContainer {
        display: flex;
        justify-content: space-between;

        @include desktop {
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
        }

        .infoWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .buttonsWrapper {
            display: flex;
            flex-direction: row;
            gap: 16px;

            @include tablet-portrait {
                flex-direction: column;
            }
        }
    }

    .userHeaderContainer {
        display: flex;
        justify-content: flex-start;

        .infoWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
}

.tabsWrapper {
    padding: 1rem 0 1rem 0;

    .toDoTasksWrapper {
        overflow-y: scroll;
        max-height: 68vh;
        padding: 1rem;
        display: flex;

        .toDoTasksContainer {
            padding: 1rem 1rem 0 1rem;
            display: flex;
            flex-direction: column;
            width: 100%;

            .timeWrapper {
                border-left: 2px solid #8e99a7;
                padding-top: 1rem;
                padding-bottom: 2rem;
                display: flex;
                align-items: center;

                .bigDot {
                    height: 16px;
                    width: 16px;
                    border-radius: 10px;
                    display: inline-block;
                    margin-left: -9px;
                    background-color: #5b80ba;
                }

                .bigDotTopVariant {
                    margin-top: -2.25px;
                }
            }

            .topVariant {
                padding-top: 0;
            }

            .toDoTextWrapper {
                border-left: 2px solid #8e99a7;
                padding: 1rem 0;
            }

            .noBorder {
                border-left: none;
            }
        }
    }
}

.addTaskWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.filterTaskWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
