.profile-header {
    background-color: #ffffff;
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    box-shadow: #eaeef4 0px 4px 16px -16px, #eaeef1 0px 8px 32px -32px;
    gap: 1rem;
}

.setting-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 1090px;
    margin: 0 auto;

    .language-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        margin: 1rem 0 2rem 0;

        .language-container {
            border-radius: 20px;
            background: #ffffff;
            padding: 2rem;
            box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include tablet-portrait {
                text-align: center;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
            }

            .language-left {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }
    }

    .permissions-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        margin: 1rem 0 2rem 0;

        .permissions-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include phone-only {
                align-items: flex-start;
                flex-direction: column;
                gap: 1rem;
            }
        }
    }

    .profile-wrapper {
        display: flex;
        gap: 2rem;

        @include tablet-portrait {
            flex-direction: column;
        }

        .profile-left {
            display: flex;
            justify-content: flex-end;
            width: 35%;
            flex-shrink: 0;

            @include tablet-portrait {
                width: 100%;
                justify-content: center;
            }

            .left-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }

        .profile-right {
            display: flex;
            justify-content: flex-start;
            width: 75%;
            flex-shrink: 0;

            @include tablet-portrait {
                width: 100%;
                justify-content: center;
            }

            .right-container {
                border-radius: 20px;
                padding: 2rem;
                background-color: #ffffff;
                box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
                width: 400px;

                .row-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .row-info {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                    }
                }
            }
        }
    }

    .actions-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        gap: 1rem;

        .password-wrapper {
            border-radius: 20px;
            background-color: #ffffff;
            box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
            width: 100%;
            padding: 2rem;
            margin-bottom: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include tablet-portrait {
                flex-direction: column;
            }

            .action-info {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @include tablet-portrait {
                    margin-bottom: 1rem;
                }
            }
        }

        .close-wrapper {
            border-radius: 20px;
            background-color: #cd2929;
            box-shadow: #eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px;
            width: 100%;
            padding: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include tablet-portrait {
                flex-direction: column;
            }

            .action-info {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @include tablet-portrait {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.change-container {
    display: flex;
    align-items: center;
    gap: 2rem;

    @include phone-only {
        flex-direction: column;
        padding-top: 0.8rem;
    }

    .change-left {
        width: 60%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include phone-only {
            align-items: center;
            width: 100%;
        }
    }

    .change-right {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        @include phone-only {
            width: 80%;
            margin: 0 auto;
            align-items: center;
        }
    }
}

.change-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
