.accordion_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .accordion-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 25px;
        column-gap: 50px;

        > div {
            width: 25%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    .element {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}

.footer {
    width: 100%;
    text-align: right;
    margin-top: 30px;
}
