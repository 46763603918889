.borderWrap {
    border-bottom: 1px solid #f1f1f1;
}

.permissions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 19px;
    cursor: pointer;

    .permissionsIconWrapper {
        display: flex;
        align-items: center;
        gap: 13px;
        flex: 1;
    }

    .headerName {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.62);
    }

    .headerTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.3);
        flex: 1;
        text-align: center;
    }

    .arrowIconWrapper {
        flex: 1;
        text-align: end;
    }
}

.collapseWrapper {
    .permissionsWrapper {
        padding: 0.5rem 16px 0px 18px;
    }
    .inputWrapper {
        padding: 16px;
    }

    .rolesCreated {
        padding: 17px 18px;
    }
}

.roleComponentWrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 10px;

    .roleComponentNameContainer {
        display: flex;
        align-items: center;
        gap: 13px;
        margin-left: 38px;
    }
}

.singlePermissionWrapper {
    cursor: pointer;
    margin-bottom: 12px;

    .permissionRow {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    .permissionNameContainer {
        display: flex;
        align-items: center;
    }

    .permissionControlsWrapper {
        padding: 11px 16px;
    }
}

.controlsWrapper {
    border-top: 1px solid #f1f1f1;
    padding: 10px 16px 0px 16px;
    display: flex;
    justify-content: space-between;
}
