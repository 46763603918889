@import 'styles/mixins.scss';

.hoverArrow {
    cursor: pointer;
    &:hover {
        color: #5b80ba;
    }
}

.clientOrderWrapper {
    display: flex;
    flex-direction: column;

    .headerWrapper {
        padding: 1rem 2.5rem;
        display: flex;
        justify-content: space-between;

        @include special-sections {
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
        }

        .navigationWrapper {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        .buttonsWrapper {
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
            align-items: flex-end;

            @include small-phone-only {
                flex-direction: column;
                gap: 1rem;
            }

            .headerLeft {
                display: flex;
                gap: 0.25rem;
                flex-direction: column;
                align-items: center;
            }

            .headerRight {
                display: flex;
                gap: 0.25rem;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .dataOne {
        padding: 1rem 2.5rem;
        display: flex;

        @include phone-only {
            flex-direction: column;
            gap: 1rem;
        }

        .column {
            width: 50%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include phone-only {
                width: 100%;
            }

            .row {
                display: flex;

                .cellLeft {
                    flex: 1;
                }

                .cellRight {
                    flex: 1.5;
                }
            }
        }
    }

    .dataTwo {
        padding: 1rem 2.5rem;
        display: flex;
        justify-content: space-between;

        @include phone-only {
            button {
                align-self: center;
            }
        }

        .series {
            display: flex;
            gap: 4rem;

            @include phone-only {
                flex-direction: column;
                gap: 0.5rem;
            }
        }
    }

    .dataThree {
        padding: 1rem 2.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .row {
            display: flex;
            gap: 4rem;

            @include phone-only {
                gap: 1.5rem;
            }

            &:nth-child(4) {
                .right {
                    @include tablet-landscape {
                        align-items: baseline;
                        gap: 1rem;
                    }

                    @include phone-only {
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .buttons {
                        @include tablet-landscape {
                            flex-direction: column;
                            align-items: flex-end;
                        }
                    }
                }
            }

            .left {
                flex: 1.15;
                display: flex;
                justify-content: space-between;

                @include desktop {
                    flex-direction: column;
                    gap: 0.5rem;
                }

                @include phone-only {
                    flex: 1.5;
                }
            }

            .right {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include phone-only {
                    flex: 0.5;
                }

                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    gap: 0.5rem;
                    align-items: center;
                }
            }
        }
    }

    .dataFour {
        padding: 1rem 2.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .row {
            display: flex;
            gap: 4rem;

            .left {
                flex: 1.15;
                display: flex;
                justify-content: space-between;

                @include phone-only {
                    flex-direction: column;
                    gap: 0.5rem;
                }
            }

            .right {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                @include phone-only {
                    align-items: center;
                }
            }
        }
    }

    .feedback {
        padding: 1rem 2.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        label {
            display: flex;
            align-items: center;
            gap: 1rem;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.62);

            input[type='checkbox'] {
                /* Add if not using autoprefixer */
                -webkit-appearance: none;
                appearance: none;
                /* For iOS < 15 to remove gradient background */
                background-color: #fff;
                /* Not removed via appearance */
                margin: 0;
            }

            input[type='checkbox'] {
                appearance: none;
                background-color: #fff;
                margin: 0;
                font: inherit;
                color: rgb(0, 0, 0);
                width: 16px;
                height: 16px;
                border: 2px solid #757575;
                border-radius: 3px;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
            }

            input[type='checkbox']::before {
                content: '';
                width: 10px;
                height: 10px;
                transform: scale(0);
                border-radius: 2px;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 10px 10px #5b80ba;
            }

            input[type='checkbox']:checked::before {
                transform: scale(1);
            }
        }
    }

    .documentsWrapper {
        padding: 1rem 2.5rem;

        .documents {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .title {
            margin-bottom: 1rem;
        }

        .button {
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;

            @include phone-only {
                justify-content: center;
            }
        }
    }

    .comments {
        padding: 0 2.5rem 2rem 2.5rem;
    }
}
