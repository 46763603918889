.mainFormClient {
    background-color: #ffffff;
    border-radius: 16px;

    .radioButtonStyling {
        display: flex;
        flex-direction: row;
        margin-left: 50px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.persoanaFizicaMain {
    padding: 0px 30px;
}

.persoanaJuridicaMain {
    padding: 0px 30px;
}
