.header {
    background-color: #ffffff;
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: #eaeef4 0px 4px 16px -16px, #eaeef1 0px 8px 32px -32px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 1090px;
    margin: 0 auto;
}

.generalAdministration {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.unitsCharacteristics {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.dot {
    height: 4px;
    width: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    display: inline-block;
    vertical-align: 3px;
}

@media screen and (max-width: 640px) {
    .generalAdministration {
        justify-content: center;
    }

    .unitsCharacteristics {
        justify-content: center;
    }
}
