.tabsWrapper {
    width: 100%;
    background-color: white;
    border-radius: 20px;

    .buttonWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 1rem;
        gap: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .tabsWrapper {
        .buttonWrapper {
            padding: 1rem;
        }
    }
}

@media screen and (max-width: 640px) {
    .tabsWrapper {
        .buttonWrapper {
            flex-direction: column;
        }
    }
}
